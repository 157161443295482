

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";

function SectionPartners(props) {

    const { t, i18n } = useTranslation()

    const _data = [
        'АКБ Крамниця',
        'LikeWork',
        'Ukrsocks',
        'Bybit',
        'Binance',
        'HostPro'
    ];

    return (<div id={'partners'}>


        <Section title={''} name={''} child={<div className={'rounded-5 bg-dark text-white shadow-lg'}>

            <div className="container m-5 m-auto container p-5 text-start ">

                <div className="d-flex align-items-center">
                    <h5 className={'lead display-'}>
                        {/*{t('partners.title')}*/}
                        Client & Partners <span className={'p-1'}></span>
                    </h5>
                    <hr width={'80%'} style={{color: "white"}} />
                </div>

                <div className="d-flex flex-wrap mt-5 align-items-center">


                        {
                            _data.map((item, i) =>  <div className={'col-md-2 col-2 text-center'}>
                                {
                                    item
                                }
                            </div>)
                        }


                </div>

            </div>

        </div>} link={'/'} linkName={''}/>

    </div>);
}

export default SectionPartners;
