import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import DetailTrackingProjectPage from "./detail";

function TrackingProjectPage(props) {

    const { t, i18n } = useTranslation()

    const [accessCode, setAccessCode] = useState("");

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code')

    if(code){
        return <DetailTrackingProjectPage code={code} />;
    }

    const _handleCheck = () => {
        window.location.href = '/project/tracking?code=' + accessCode
    }

    return (<div className={'container h-100 pt-5'}>

        <h1>Tracking project  {code}</h1>
        <a href="/" className={'nav-link mt-5'}>
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 12h14M5 12l4-4m-4 4 4 4"/>
            </svg>
            Back
        </a>

        <div className="container d-flex text-dark pt-5">

            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center m-auto">
                <div className={'form mt-5'}>
                    <input type="text" placeholder={'Access code'} value={accessCode} onChange={(e) => setAccessCode(e.target.value)} className={'p-3 rounded-0 border-0 border-bottom'}/>
                    <button type={'button'} onClick={_handleCheck} className={'btn btn-dark rounded-0 p-3'}>
                        Check
                    </button>
                </div>
            </div>

        </div>
    </div>);
}

export default TrackingProjectPage;
