

import React from 'react';
import {useTranslation} from "react-i18next";
import CardPortfolioService from "../../components/card_portfolio_service";
import SliderContent from "../../components/slider/default";
import CardPortfolio from "../../components/card_portfolio";
import {useParams} from "react-router";

function SinglePortfolioPage(props) {

    let {slug} = useParams();

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    project
                </small>

                <h2 className={'display-1'}>
                    Title project work
                </h2>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-center">
                <div className="item p-1">
                    <small className="text-secondary border rounded-5 p-2">
                        Web develop
                    </small>
                </div>

                <div className="item p-1">
                    <small className="text-secondary border rounded-5 p-2">
                        Landing
                    </small>
                </div>

                <div className="item p-1">
                    <small className="text-secondary border rounded-5 p-2">
                        Lukyanchuk Prod.
                    </small>
                </div>

                <div className="item p-1">
                    <small className="text-secondary border rounded-5 p-2">
                         Commercial project
                    </small>
                </div>
            </div>
        </div>

        <div className="container-fluid text-dark text-start pt-5 mt-5">

            <div className="project-cover-image p-3">
                <img src="https://plus.unsplash.com/premium_photo-1661575479396-96170d1b0187" alt="macbook" className={'rounded-5 img-fluid'}/>
            </div>


            <div className="description container">

                <div className="description-title mt-5">
                    <h2 className={'display-1 text-secondary1'}>
                        Review
                    </h2>
                </div>

                <div className="description-content pb-5">
                    <p className={'lead'} style={{fontSize: "30px"}}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea esse eum eveniet ipsum iste laboriosam laborum nesciunt, nisi non, officiis perferendis porro, repudiandae temporibus veritatis voluptatum. Fugiat optio recusandae veritatis!
                    </p>
                    <p className={'lead'} style={{fontSize: "30px"}}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea esse eum eveniet ipsum iste laboriosam laborum nesciunt, nisi non, officiis perferendis porro, repudiandae temporibus veritatis voluptatum. Fugiat optio recusandae veritatis!
                    </p>
                </div>

            </div>


            {/*TODO: slider content*/}
            <div className="slider-content mt-5 pb-5">
                <SliderContent />
            </div>

            <div className="container-fluid bg-dark text-white rounded-5 p-md-5 p-3 mt-5">

                <div className="container row m-auto mt-5">
                    <div className="col-md-6">
                        <h2 className={'display-4'}>Task</h2>
                        <p className={'lead'} style={{fontSize: "30px"}}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea esse eum eveniet ipsum iste laboriosam laborum nesciunt, nisi non, officiis perferendis porro, repudiandae temporibus veritatis voluptatum. Fugiat optio recusandae veritatis!
                        </p>
                    </div>
                    <div className="col-md-6">
                        <h2 className={'display-4'}>
                            Stack Technologies
                        </h2>

                        {/*TODO: add type show portfolio by category/service + limit*/}
                        <div className="d-flex flex-wrap align-items-center mt-1 mb-51">
                            {
                                [...Array(8)].map((e, i) =>

                                    <a href="" className="btn btn-light m-1 border- rounded-5 p-3 pt-2 pb-2">
                                        <i>
                                            <small className="text-secondary">
                                                #name-{i+1}
                                            </small>
                                        </i>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="description container mt-5">

                    <div className="description-title">
                        <h2 className={'display-4 text-secondary1'}>Solution</h2>
                    </div>

                    <div className="description-content">
                        <p className={'lead'} style={{fontSize: "30px"}}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea esse eum eveniet ipsum iste laboriosam laborum nesciunt, nisi non, officiis perferendis porro, repudiandae temporibus veritatis voluptatum. Fugiat optio recusandae veritatis!
                        </p>
                        <p className={'lead'} style={{fontSize: "30px"}}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea esse eum eveniet ipsum iste laboriosam laborum nesciunt, nisi non, officiis perferendis porro, repudiandae temporibus veritatis voluptatum. Fugiat optio recusandae veritatis!
                        </p>
                    </div>

                </div>

            </div>

            <div className="share container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className={'display-4'}>Download / View</h2>
                        <div className={'mt-5'}>
                            <button className="btn btn-light rounded-5 p-4 pt-3 pb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                     className="bi bi-download" viewBox="0 0 16 16">
                                    <path
                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                    <path
                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                </svg> <span className="display-6 p-3">
                                    Project file
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h2 className={'display-4'}>Share</h2>
                        <div className="d-flex flex-wrap align-items-center mt-5">
                            {
                                [...Array(5)].map((e, i) =>

                                    <a href="" className="btn btn-light m-1 border- rounded-5 p-3 pt-2 pb-2">
                                        <i>
                                            <small className="text-secondary">
                                                share iten{i+1}
                                            </small>
                                        </i>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className="example-service-project-work mt-5">

                <div className="container">
                    <h2 className={'display-4'}>Similar project</h2>
                </div>

                {/*TODO: add type show portfolio by category/service + limit*/}
                <div className="d-flex flex-wrap align-items-center mt-5">

                    <CardPortfolio />

                    <CardPortfolio />

                    <CardPortfolio />
                </div>
            </div>

            <div className="tags-project-work mt-5">

                <div className="container">
                    <h2 className={'display-4'}>Tags</h2>

                    {/*TODO: add type show portfolio by category/service + limit*/}
                    <div className="d-flex flex-wrap align-items-center mt-5">
                        {
                            [...Array(15)].map((e, i) =>

                            <a href="" className="btn btn-light m-1 border- rounded-5 p-3 pt-2 pb-2">
                                <i>
                                    <small className="text-secondary">
                                        #tag-name-{i+1}
                                    </small>
                                </i>
                            </a>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>

        <div className="text-end p-5">
            <a href="/services" className={'text-decoration-none text-dark display-4'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>get contact</b>
            </a>
        </div>


    </div>);
}

export default SinglePortfolioPage;
