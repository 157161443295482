

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function InfoPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100 pt-5'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1>Information</h1>

            <div className="row">
                <div className="col-md-8">
                    <AccordionItemCustom data={[

                        {
                            id: 1,
                            title: "#contacts",
                            content: <div>
                                <p>
                                    email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                                </p>
                                <p>
                                    tel.: <a href="tel.: +380 (98) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (98) 075 13 57</a>
                                </p>
                                <p>
                                    tel.: <a href="tel.: +380 (63) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (63) 075 13 57</a>
                                </p>
                                <p>
                                    adress.: <a href="https://www.google.com/maps"  className={'text-decoration-none text-secondary lead'}>10001, Ukraine</a>
                                </p>
                            </div>
                        },
                        {
                            id: 2,
                            title: "#payments",
                            content: <div>
                                <h5 className={'mt-5'}>payments</h5>
                                <img src="https://benya.pro/wp-content/uploads/2024/04/image.png" alt="payments" className={'rounded-2 shadow w-100'}/>

                                <h3 className={'mt-5'}>
                                    USDT
                                </h3>
                                <small className="text-secondary">
                                    TRC20
                                </small>
                                <p>
                                    TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                                </p>
                            </div>
                        },
                        {
                            id: 3,
                            title: "#privacy-policy",
                            content: <DataPivacyPolicy />
                        },
                        {
                            id: 4,
                            title: "#terms-of-use",
                            content: <div>
                                <b>Text</b>
                            </div>
                        },
                        {
                            id: 5,
                            title: "#links",
                            content: <div>
                                <a href="/site-map" className={'text-decoration-none text-secondary nav-link'}>site map <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                                                                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                                             fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                                </svg></a>
                            </div>
                        },
                        {
                            id:6,
                            title: "#questions-and-answers",
                            content: <div>
                                <b>Text</b>
                            </div>
                        },
                    ]} />
                </div>
                <div className="col-md-3">
                    <div className={'sticky-top pt-5'}>
                        <h1>Content</h1>
                        <ul>
                            <li><a href="#questions-and-answers" className={'nav-link'}>Questions & Answers</a></li>
                            <li><a href="#contacts" className={'nav-link'}>Contacts</a></li>
                            <li><a href="#payments" className={'nav-link'}>Payments</a></li>
                            <li><a href="#links" className={'nav-link'}>Links</a></li>
                            <li><a href="#privacy-policy" className={'nav-link'}>Privacy policy</a></li>
                            <li><a href="#terms-of-use" className={'nav-link'}>Term of use</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default InfoPage;
