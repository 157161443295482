

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function MobileAppPage(props) {

    const { t, i18n } = useTranslation()

    const _workProcessData = [
        {
            title: "Discovery",
            description: "objectives, larget audience, and urique requirements."
        },
        {
            title: "Design & Develop",
            description: "Itranslate Insights hto tangitle designs, crating intuitive interfaces and seeniess esperiences."
        },
        {
            title: "Test & Launch",
            description: "I rigorously test the product to ensure functionalky and performance before a smooth and successful launch"
        }
    ];

    return (<div className={'min-vh-100'}>


        <div className="container text-dark text-start mt-5">
            <h1>Mobile App</h1>
            <small className="text-secondary">
                Release date: <b>N/A</b>
            </small>

            <div className="d-flex align-items-center justify-content-between mt-5">

                <div className="col-md-6">
                    <div className="d-flex flex-wrap">

                        {
                            _workProcessData.map((item, i) =>  <div className="col-md-6 col-12">
                                <div className="content border shadow-sm rounded p-3 m-2">
                                    <small className="text-secondary">
                                        0{i+1}
                                    </small>

                                    <h4 className={'mt-3'}>
                                       Title {i+1}
                                       {/*{item.title}*/}
                                    </h4>
                                    <p>
                                        {/*{item.description}*/}
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi doloremque iste laboriosam laudantium quisquam repudiandae sint. Ab ad assumenda atque dicta omnis. Asperiores dolorum enim excepturi illo porro totam? Fugiat.
                                    </p>
                                </div>
                            </div>)
                        }

                    </div>
                </div>

                <div className="col-md-6 text-center p-5">
                    <img
                         // src="https://www.pngitem.com/pimgs/m/525-5259250_apple-ios-13-iphone-hd-png-download-apple.png"
                        src={'https://i.pinimg.com/originals/f3/61/53/f3615368cf0f682093efd99ca9523393.png'}
                        alt="app" className={'img-fluid'}/>
                </div>


            </div>

            <a href="/" className={'nav-link mt-5'}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                </svg>
                Back
            </a>

        </div>

        <div className="btn-download fixed-bottom text-end container">
            <a href="#" className={'btn btn-dark rounded-0 p-3 col-md-3 col-12 shadow-sm mb-3'}>Download</a>
        </div>
    </div>);
}

export default MobileAppPage;
