import React, {useEffect, useState} from "react";
import axios from "axios";
import CardPortfolio from "../../components/card_portfolio";

function PortfolioApi(){

    const BLOG_API_URL = 'https://office.lukyanchuk.com/api/front-end/projects?limit=6&sort=DESC';

    const [data, setData] = useState([]);

    useEffect(() => {

        axios.get(BLOG_API_URL, {}).then( response => {

            setData(response.data.data)

        }).catch(e => console.log(e.message))

    }, [])

    return (<>
        {
            data && data.map((e, i) => e.image.url !== null && <CardPortfolio project={e} i={i} />)
        }
    </>);
}

export default PortfolioApi;
