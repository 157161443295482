import React from 'react';

import {useTranslation} from "react-i18next";
import ProgressBar from 'react-bootstrap/ProgressBar';

function DetailTrackingProjectPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'container min-vh-100 mt-5'}>

        <h1>Project Name</h1>
        <div className={'d-flex justify-content-between'}>
            <p>
                service name
            </p>

            <p>
                in process
            </p>

            <p>
                deadline date
            </p>
        </div>

        <a href="/project/tracking" className={'nav-link'}>
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 12h14M5 12l4-4m-4 4 4 4"/>
            </svg>
            Exit
        </a>
        <small className="text-secondary">Access code: <b>{props.code}</b></small>

        <div className="container d-flex flex-wrap text-dark">

            <div className="border-0 col-md-6 col-12 m-auto">
                <h2>History</h2>
                <br/>
                <ProgressBar now={12} label={`${12}%`} variant="dark" />

                <div className="history mt-5">
                    <div className="item-history pt-3">
                        <small className="text-secondary">
                            22.11.2024
                        </small>
                        <div className="d-flex align-items-center justify-content-between">
                            <h3>
                                Create project
                            </h3>
                            <small className="text-secondary lead">
                                +12%
                            </small>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, deserunt eaque enim esse explicabo labore libero mollitia nisi officia quo, totam vel, voluptatem. Animi autem beatae, odio possimus quasi velit.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default DetailTrackingProjectPage;
