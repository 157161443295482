

import React from 'react';
import {useTranslation} from "react-i18next";

function OfferPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'opacity-25'}>Cooperation</h1>
            <br/>
            <h2>
                Ready to build something great?
            </h2>
            <p className={'display-5'}>
                Let me know what you are thinking of and i’ll get back to you shortly.
            </p>
           <div className="d-flex align-items-start justify-content-start">
               <div className="short-connect col-md-5 d-md-block d-none">
                   <br/><br/>
                   <h3>
                       Connect with me
                   </h3>
                   <br/>
                   <div>
                       <p>
                           email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                       </p>
                       <p>
                           messenger:  <a href="https://t.me/YaroslavLukyanchuk" className={'text-decoration-none text-secondary lead'} target={'_blank'}>telegram</a>
                       </p>
                       {/*<p>*/}
                       {/*    tel.: <a href="tel.: +380 (98) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (98) 075 13 57</a>*/}
                       {/*</p>*/}
                       {/*<p>*/}
                       {/*    tel.: <a href="tel.: +380 (63) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (63) 075 13 57</a>*/}
                       {/*</p>*/}
                   </div>
               </div>
               <div className="form">
                   <h3>
                       Let's work together on project
                   </h3>
                   <br/>
                   <form action="">
                       <input type="text" placeholder={'Name'} className={'form-control border-0 border-bottom rounded-0'}/>
                       <input type="text" placeholder={'Phone / Email'} className={'form-control border-0 border-bottom rounded-0 mt-3'}/>
                       <input type="text" placeholder={'Something about idea / project'} className={'form-control border-0 border-bottom rounded-0 mt-3'}/>
                       <br/>
                       <button className="btn btn-dark p-3 rounded-pill col-12 col-md-4">
                           Submit
                       </button>
                   </form>
               </div>
           </div>

        </div>
    </div>);
}

export default OfferPage;
