
import React from 'react';
import {useTranslation} from "react-i18next";
import {MouseParallax} from "react-just-parallax";
import {MotionAnimate} from "react-motion-animate";
import './../css/book.css';

function BooksSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="row justify-content-center align-items-center p-2 rounded-5">
            <div className="col-md-6 col-12 p-5">
                <h5 className={'title-section mt-md-2 mt-2'}>
                    {t('books')}
                </h5>
                <p className={'lead text-secondary mt-2 mb-5 pb-2'}>
                    2024  {/*{t('clients.text')}*/}
                </p>

                <a href="https://books.lukyanchuk.com/book/one-percent-changes-do-it-now/" target={'_blank'} className={'text-dark text-decoration-none'}>
                    <h2 className={'display-2'}>
                        1% Змін.
                    </h2>
                    <p className="text-secondary">
                        Час зроби це зараз!
                    </p>
                </a>

                <div className="row mt-5">
                    <div className="col-md-8 col-12">
                        <div>
                            <div style={{opacity: "1", transform: "none"}}>
                                <div className="row"><a
                                    href="https://shop.lukyanchuk.com"
                                    className="btn btn-outline-light shadow-lg bg-dark text-white p-2 pt-3 pb-3 col-md-8 col-12 rounded-5"><span
                                    className="lead display-"><svg className="w-6 h-6 text-gray-800 dark:text-white"
                                                                   aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                   width="24" height="24" fill="none"
                                                                   viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"/>
</svg>
 Купить</span></a><a
                                    href="https://books.lukyanchuk.com/books"
                                    className="btn text-dark p-2 pt-3 pb-3 col-md-4 col-12 rounded-5 d-flex align-items-center">
                                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                         viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                                    </svg>

                                    <small className={'p-2'}>детальніше</small></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-md-6 text-center d-none d-md-block">
                {/*<MouseParallax>*/}

                <MotionAnimate
                    animation='fadeInUp'
                    reset={true}
                    distance={100}
                    delay={1}
                    speed={1}>
                    <a
                        className="book-container col-md-6 col-12"
                        href="https://books.lukyanchuk.com/book/one-percent-changes-do-it-now/"
                        rel="noreferrer noopener"
                    >
                        <div className="book">
                            <img
                                className={'img-fluid'}
                                alt="book"
                                src={'https://books.lukyanchuk.com/image/cover/book_cover.png'}
                            />
                        </div>
                    </a>

                </MotionAnimate>
                {/*</MouseParallax>*/}

            </div>

        </div>
    </div>);
}

export default BooksSection;
