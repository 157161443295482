

import React from 'react';
import {useTranslation} from "react-i18next";

function SiteMapPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1>Site Map</h1>
        </div>
    </div>);
}

export default SiteMapPage;
