import React, {useEffect} from "react";

import FooterComponent from "../footer";
import HeaderComponent from "../header";

function PageLayouts(props) {

    const [isDarkMode, setDarkMode] = React.useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);


    return (
      <div className="page-layout container-fluid">

          <HeaderComponent
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />

          <main className={'min-vh-100'}>

              {
                  props.child
              }

          </main>

          <FooterComponent />

          <div className="bg-dark fixed-bottom col-md-2 text-white m-5 text-center d-none d-md-block">

              <a href="mailto:yarik@lukyanchuk.com" target={'_blank'} className={'nav-link p-3 shadow-sm'}>
                  <small className="email">
                      yarik@lukyanchuk.com
                  </small>
              </a>
          </div>

      </div>
    );
};

export default PageLayouts;
