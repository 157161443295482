

import React from 'react';
import {useTranslation} from "react-i18next";
import SectionServices from "../../components/section/services";

function ServicesPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100 pt-5 mt-5'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    послуги
                </small>

                <h2 className={'display-1'}>
                    Що я можу вам запропонувати
                </h2>

            </div>
        </div>


        <div className="services">

            <div className="p-md-5 p-0 d-flex flex-wrap justify-content-between align-items-start">

                <div className="col-md-4 col-12 p-2">
                    <a href="/services/mobile-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                src="https://www.shutterstock.com/image-vector/man-laptop-computer-3d-icon-600nw-2166535919.jpg"
                                alt="support" className={'img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Support & consultation
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/mobile-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                src={"https://img.freepik.com/free-photo/3d-hand-hold-mobile-phone-with-blank-screen_107791-15086.jpg"}
                                // src={"https://png.pngtree.com/png-vector/20240128/ourmid/pngtree-3d-phone-mockup-object-png-image_11508013.png"}
                                // src={"https://cdn3d.iconscout.com/3d/premium/thumb/mobile-app-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--application-smartphone-startup-saas-pack-business-illustrations-3851512.png?f=webp"}
                                // src={"https://cdn3d.iconscout.com/3d/premium/thumb/mobile-development-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--app-design-application-technology-pack-user-interface-illustrations-4708214.png"}
                                // src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png"
                                alt="iphone" className={'img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg>
                            Mobile develop
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                src={"https://st2.depositphotos.com/11576988/46953/v/450/depositphotos_469538504-stock-illustration-laptop-isometric-style-portable-computer.jpg"}
                                // src="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/refurb-mbp13-m2-spacegray-202208?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1659374923283"
                                alt="macbook" className={'rounded-5 img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Web develop
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                // src={"https://static.vecteezy.com/system/resources/thumbnails/011/500/860/small_2x/error-3d-icon-png.png"}
                                // src="https://cdn3d.iconscout.com/3d/premium/thumb/error-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--exclamation-warning-alert-danger-black-gold-crypto-pack-science-technology-illustrations-3411211.png"
                                src={"https://cdn3d.iconscout.com/3d/premium/thumb/error-3d-icon-download-in-png-blend-fbx-gltf-file-formats--warning-alert-danger-notification-pack-science-technology-icons-7531475.png?f=webp"}
                                alt="macbook" className={'rounded-5 img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Fix bugs
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                // src={"https://static.vecteezy.com/system/resources/thumbnails/011/500/860/small_2x/error-3d-icon-png.png"}
                                // src="https://cdn3d.iconscout.com/3d/premium/thumb/api-3d-icon-download-in-png-blend-fbx-gltf-file-formats--website-development-web-design-pack-icons-9618513.png?f=webp"
                                src={'https://cdn3d.iconscout.com/3d/premium/thumb/plugin-in-socket-3d-illustration-download-png-blend-fbx-gltf-file-formats--plug-power-charging-electric-electrician-tools-pack-appliances-illustrations-4056722.png?f=webp'}
                                alt="macbook" className={'rounded-5 img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Integration
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                // src={"https://static.vecteezy.com/system/resources/thumbnails/011/500/860/small_2x/error-3d-icon-png.png"}
                                src="https://static.vecteezy.com/system/resources/thumbnails/035/321/929/small_2x/limited-time-offer-deadline-symbol-stopwatch-3d-rendering-icon-illustration-isolated-png.png"
                                alt="macbook" className={'rounded-5 img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Part time / Project job
                        </small>
                    </a>
                </div>

                <div className="col-md-4 col-6 p-2">
                    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>
                        <div className="service-item hover-div border1 rounded-5">
                            <img
                                src="https://cdn3d.iconscout.com/3d/premium/thumb/designer-working-on-design-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--graphic-designing-creative-process-vector-idea-pack-development-illustrations-4971259.png?f=webp"
                                alt="macbook" className={'rounded-5 img-fluid'}/>
                        </div>
                        <small className="text-secondary">
                            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                            </svg> Design
                        </small>
                    </a>
                </div>

                {/*<div className="col-md-4 col-6 p-2">*/}
                {/*    <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none'}>*/}
                {/*        <div className="service-item hover-div border1 rounded-5">*/}
                {/*            <img*/}
                {/*                src="https://cdn3d.iconscout.com/3d/premium/thumb/designer-working-on-design-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--graphic-designing-creative-process-vector-idea-pack-development-illustrations-4971259.png?f=webp"*/}
                {/*                alt="macbook" className={'rounded-5 img-fluid'}/>*/}
                {/*        </div>*/}
                {/*        <small className="text-secondary">*/}
                {/*            <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"*/}
                {/*                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"*/}
                {/*                 viewBox="0 0 24 24">*/}
                {/*                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                      stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>*/}
                {/*            </svg> Audit / SEO / Advertising / Branding*/}
                {/*        </small>*/}
                {/*    </a>*/}
                {/*</div>*/}


                {/*<div className="col-md-6 col-6 border rounded-5 shadow p-5">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" fill="currentColor"*/}
                {/*         className="bi bi-laptop" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>*/}
                {/*    </svg>*/}
                {/*    <p>*/}
                {/*        <small className={'text-secondary'}>*/}
                {/*            web develop*/}
                {/*        </small>*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<div className="col-md-6 col-6 border rounded-5 shadow p-5">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" fill="currentColor"*/}
                {/*         className="bi bi-phone" viewBox="0 0 16 16">*/}
                {/*        <path*/}
                {/*            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>*/}
                {/*        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>*/}
                {/*    </svg>*/}
                {/*    <p>*/}
                {/*        <small className={'text-secondary'}>*/}
                {/*            mobile develop*/}
                {/*        </small>*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>

        </div>


        {/*<div className="container-fluid text-dark text-start pt-5 mt-5">*/}
        {/*    <div className="p-5 d-flex flex-wrap justify-content-between align-items-start">*/}

        {/*        <div className="col-md-6 col-6 p-2 text-center">*/}
        {/*            <div className="service-item hover-div border rounded-5">*/}
        {/*                <img src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png" alt="iphone" className={'rounded-5 img-fluid'}/>*/}
        {/*            </div>*/}
        {/*            <h2 className={'mt-2'}>*/}
        {/*                Мобільна розробка*/}
        {/*            </h2>*/}
        {/*        </div>*/}

        {/*        <div className="col-md-6 col-6 p-2 text-center">*/}
        {/*            <div className="service-item hover-div border rounded-5">*/}
        {/*                <img src="https://www.agilecomputers.co.uk/wp-content/uploads/business-it-support-specialists-image-1.jpg" alt="macbook" className={'rounded-5 img-fluid'}/>*/}
        {/*            </div>*/}
        {/*            <h2 className={'mt-2'}>*/}
        {/*                Веб розробка*/}
        {/*            </h2>*/}
        {/*        </div>*/}

        {/*        <div className="col-md-6 col-6 p-2 text-center">*/}
        {/*            <div className="service-item hover-div border rounded-5">*/}
        {/*                <img src="https://www.mediaheroes.com.au/wp-content/uploads/2023/09/graphic-design-styles-media-heroes-banner.webp" alt="macbook" className={'rounded-5 img-fluid'}/>*/}
        {/*            </div>*/}
        {/*            <h2 className={'mt-2'}>*/}
        {/*                Дизайн*/}
        {/*            </h2>*/}
        {/*        </div>*/}

        {/*        <div className="col-md-6 col-6 p-2 text-center">*/}
        {/*            <div className="service-item hover-div border rounded-5">*/}
        {/*                <img src="https://www.liveagent.com/app/uploads/2024/03/help-desk-metrics-feature-img.jpg" alt="macbook" className={'rounded-5 img-fluid'}/>*/}
        {/*            </div>*/}
        {/*            <h2 className={'mt-2'}>*/}
        {/*                Підтримка та супровід*/}
        {/*            </h2>*/}
        {/*        </div>*/}

        {/*    </div>*/}

        {/*    <div className="text-end p-5">*/}
        {/*        <a href="/services" className={'text-decoration-none text-dark display-4'}>*/}
        {/*            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"*/}
        {/*                 className="bi bi-arrow-right" viewBox="0 0 16 16">*/}
        {/*                <path fill-rule="evenodd"*/}
        {/*                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>*/}
        {/*            </svg> <b>get connect</b>*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/*</div>*/}

    </div>);
}

export default ServicesPage;
