import SectionWelcome from "../components/section/welcome";
import SectionAbout from "../components/section/about";
import SectionServices from "../components/section/services";
import SectionStackTechnologies from "../components/section/stack-technologies";
import SectionPortfolio from "../components/section/portfolio";
import SectionPartners from "../components/section/partners";
import SectionProducts from "../components/section/products";
import FooterComponent from "../components/footer";
import SectionBlog from "../components/section/blog";
import SectionQandA from "../components/section/qa";
import SectionMain from "../components/section/main";

function PageHome() {
    return (
        <div className="home-page">

            {/*<SectionWelcome />*/}
            <SectionMain />

            {/*<SectionAbout />*/}

            <SectionServices />

            <SectionStackTechnologies />

            <SectionPortfolio />

            {/*<SectionPartners />*/}

            <SectionProducts />

            {/*TODO: Blog and Partners add to final release*/}

            {/*<SectionBlog />*/}

            {/*<SectionPartners />*/}

            {/*<SectionBlog />*/}

            <SectionQandA />

        </div>
    );
}

export default PageHome;
