

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function ContactsPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100 pt-5'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1>{t('nav.contacts')}</h1>

            <div className="d-flex align-items-start justify-content-between">
                <div className="col-md-8 col-12">

                   <div className="mt-5">
                       <h3>connect with me</h3>
                       <p>
                         email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                       </p>
                       <p>
                           tel.: <a href="tel.: +380 (98) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (98) 075 13 57</a>
                       </p>
                       <p>
                           tel.: <a href="tel.: +380 (63) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (63) 075 13 57</a>
                       </p>
                       <p>
                           adress.: <a href="https://www.google.com/maps"  className={'text-decoration-none text-secondary lead'}>10001, Ukraine</a>
                       </p>
                   </div>



                       <div className="mt-5 d-flex flex-wrap align-items-center justify-content-between">
                           <h3>social media</h3>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>linkedin</a>
                           </p>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>instagram</a>
                           </p>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>X (twitter)</a>
                           </p>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>facebook</a>
                           </p>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>youtube</a>
                           </p>
                           <p>
                               <a href="" className={'text-decoration-none text-secondary lead'}>git</a>
                           </p>
                       </div>


                    <div className="mt-5 d-flex flex-wrap align-items-center justify-content-between">
                        <h3>referal links</h3>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>binance</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>bybit</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>hostpro</a>
                        </p>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className={'sticky-top'}>
                        <h3 className={'mt-5'}>payments credentials</h3>
                        <img src="/assets/img/qr/paypal.png" alt="payments" className={'p-2 shadow1 w-50'}/>
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="V9YGTAHG9S94Q" />
                            <input type="image" src="https://pics.paypal.com/00/s/YmIyOGMzMGEtN2QzNS00YmY3LWI3MjMtZGZlOWYxZDQ1ZDhl/file.PNG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_UA/i/scr/pixel.gif" width="1" height="1" />
                        </form>

                        <h3 className={'mt-3'}>
                            Crypto
                        </h3>
                        <small className="text-secondary">
                            USDT (TRC20)
                        </small>
                        <p>
                            TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default ContactsPage;
